import router from "@/router";
import text from './text.json';
import moment from 'moment';
export default {
  url:{
    api: 'http://127.0.0.1:8001',
    app:null
  },
  lang: localStorage.lang === undefined ? 'fr' : localStorage.lang,
  text: text,
  ste: {
    key:null,
    name:"Diday",
    logo:"https://diday.com/paytest/assets/img/logo-blue.svg",
    symbol:"€",
    colors:{
      menu:{
        primary:"#ffffff",
        secondary: "#2196f3"
      }
    }
  },
  meta:null,
  params:false,
  ajax(route, params = null, callBack) {
    const url = route.search('http') === 0 ? route : this.url.api + route;
    let the = this;
    let option = {};
    option.headers = {
      "Content-Type":"application/json",
      Authorization: this.getToken()
    }
    if(params != null){
      option.method = "POST",
      option.body = JSON.stringify(params)
    }
    fetch(url,option).then( response => {
      if(response.status === 401){
        the.destroyToken();
        the.destroyUser();
      }
      response.json().then( data =>{
        if(data.status === false){
          this.$toastr.e(data.data);
        }
        the.res = data;
        if(callBack != null) {
          callBack(the.res);
        }
      })
    });
  },
  getText(key){
    var tmp = this.text[this.lang];
    if(tmp === undefined){
      return key;
    }
    if(tmp[key] === undefined){
      return key;
    }
    return tmp[key]
  },
  timestampToDate(timestamp,datetime = true){
    var date = new Date(timestamp*1000);
    var res = date.toLocaleDateString("fr-Fr");
    if(datetime){
      res += ' '+date.toLocaleTimeString("fr-Fr");
    }
    return res;
  },
  changeLang(lang){
    localStorage.lang = lang;
    this.lang = lang;
    location.reload();
  },
  setColors(){
    var root = document.querySelector(':root');
    root.style.setProperty('--menu-primary', this.ste.colors.menu.primary);
    root.style.setProperty('--menu-secondary', this.ste.colors.menu.secondary);
  },
  getToken(){
    var t = window.localStorage.getItem("token");
    if(t === undefined || t === null){
      return false;
    }
    return t;
  },
  saveToken(token){
    window.localStorage.setItem("token", token);
  },
  saveUser(user){
    this.user = user;
  },
  saveSte(ste){
    this.ste = ste;
  },
  getUser(){
    return this.user;
  },
  destroyToken(){
    window.localStorage.removeItem("token");
    router.push({path:"/login"})
  },
  destroyUser(){
    this.user = null;
  },
  now(){
    var n = new Date().getTime();
    n = n/1000;
    return parseInt(n);
  },
  getParam(obj, id){
    var check = this.params[obj];
    if(check === undefined){
      return {};
    }
    var tmp = check.find(p => p.id === id);
    if(tmp === undefined){
      return {};
    }
    if(tmp.titre != undefined){
      tmp.titre = this.getText(tmp.titre);
    }
    return tmp;
  },
  moment(){
    return moment();
  },
  init(){
    Array.prototype.findID = function(id){
      var check = this.find(a => a.id === id)
      if(check === undefined){
        return {}
      }
      return check
    }
  }
}
