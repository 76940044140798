import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Api from '@/core/Api';
import VueToastr from "vue-toastr";
Vue.use(VueToastr);
Vue.prototype.$api = Api;
Vue.config.productionTip = false;
// Vue 3rd party plugins
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/vuesax";
new Vue({
  router,
  beforeMount() {
    this.$api.$toastr = this.$toastr
  },
  render: h => h(App)
}).$mount("#app");
