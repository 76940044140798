import Vue from "vue";
import VueRouter from "vue-router";
//import Api from '@/core/Api';
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/accueil",
    component: () => import("@/views/layout/Main"),
    children:[
      {
        path:"accueil",
        name:"Accueil",
        component: () => import("@/views/Accueil"),
      },
      {
        path:"nouveau",
        name:"Nouveau",
        component: () => import("@/views/Nouveau"),
      },
      {
        path:"recherche/:uuid",
        name:"Recherche",
        component: () => import("@/views/Recherche"),
      }
    ]
  },
];
const router = new VueRouter({
  routes,
});
/*async function backVerify(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var url = Api.url.api + '/authorization/verify'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}
async function getParams(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var url = Api.url.api + '/magic/all'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}
router.beforeEach((to, from, next) => {
  if(to.meta.logged === true){
    if(Api.params === false){
      getParams().then(res => {
        if(res.status){
          if(res.data.Societe != undefined){
            res.data.Societe.forEach((item) => {
              item.titre = item.data.raison_sociale
            });
          }
          Api.params = res.data
        }
      });
    }
    if(Api.getToken() != false){
      backVerify().then(res => {
        if(res.status === true){
          Api.saveToken(res.data.token);
          Api.saveUser(res.data);
          next();
        }else{
          next({path:'/login'})
        }
      }).catch( () => {
        next({path:'/login'})
      });
    }else{
      next({path:'/login'})
    }
  }else{
    next();
  }
})*/

export default router;
